import React from "react";
import Layout from "../components/Layout/Layout";

const SocialAwarness = () => {
  return (
    <>
      <Layout>
        <div
          className="page-title"
          style={{ backgroundImage: 'url("img/bg-page.jpg")' }}
        >
          <div className="container">
            <h1 className="title">Social Awareness</h1>
          </div>
        </div>

        <section className="s-banners">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="baner-item">
                  <img src="/img/banner-1.jpg" alt="img" />
                  <div className="baner-item-content">
                    <h2>
                      We commit to donating 5% of our annual profits to support
                      children in disadvantaged villages and to protect nature.
                    </h2>
                    <p>
                      One of our key objectives is to provide access to our
                      platform for everyone around the world, especially those
                      who cannot afford education.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="baner-item">
                  <img src="/img/banner-2.jpg" alt="img" />
                  <div className="baner-item-content">
                    <h2>Be the change we want to see in the world!</h2>
                    <p>
                      We believe each generation and individual can achieve
                      amazing progress and positive change for a better world.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default SocialAwarness;
