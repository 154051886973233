/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const LatestNews = () => {
  return (
    <>
<section className="s-blog" >
  <div className="container s-anim s-anim-active" >
    <h2 >latest news</h2>
    <p className="slogan" >
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmmpor
      incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
      nostrud.
    </p>
    <div className="row" >
      {/*=============== POST-ITEM ===============*/}
      <div className="col-12 col-md-4 post-item" >
        <div className="prod-thumbnail" >
          <a href="blog.html" >
            <img
              className="rx-lazy rx-lazy_item"
              src="img/blog-img-1.jpg"
              data-src="img/blog-img-1.jpg"
              alt="img"
              
            />
          </a>
        </div>
        <div className="post-content" >
          <div className="meta top" >
            <span className="post-by" >
              By{" "}
              <a href="#" >
                Sam Filips
              </a>{" "}
              /
            </span>
            <span className="post-date" >
              September 2019
            </span>
          </div>
          <h5 className="title" >
            <a href="blog.html" >
              Sed ut perspiciatis unde omnis iste natus error sit
            </a>
          </h5>
          <div className="meta bottom" >
            <span className="post-comments" >
              <a href="#" >
                Comments 2
              </a>
            </span>
            <span className="post-tags" >
              Tags:{" "}
              <a href="#" >
                Lorem,{" "}
              </a>
              <a href="#" >
                Drones
              </a>
            </span>
          </div>
          <a href="blog.html" className="btn" >
            more
          </a>
        </div>
      </div>
      {/*============= POST-ITEM END =============*/}
      {/*=============== POST-ITEM ===============*/}
      <div className="col-12 col-md-4 post-item" >
        <div className="prod-thumbnail" >
          <a href="blog.html" >
            <img
              className="rx-lazy rx-lazy_item"
              src="img/blog-img-2.jpg"
              data-src="img/blog-img-2.jpg"
              alt="img"
              
            />
          </a>
        </div>
        <div className="post-content" >
          <div className="meta top" >
            <span className="post-by" >
              By{" "}
              <a href="#" >
                Sam Filips
              </a>{" "}
              /
            </span>
            <span className="post-date" >
              September 2019
            </span>
          </div>
          <h5 className="title" >
            <a href="blog.html" >
              Sed ut perspiciatis unde omnis iste natus error sit
            </a>
          </h5>
          <div className="meta bottom" >
            <span className="post-comments" >
              <a href="#" >
                Comments 2
              </a>
            </span>
            <span className="post-tags" >
              Tags:{" "}
              <a href="#" >
                Lorem,{" "}
              </a>
              <a href="#" >
                Drones
              </a>
            </span>
          </div>
          <a href="blog.html" className="btn" >
            more
          </a>
        </div>
      </div>
      {/*============= POST-ITEM END =============*/}
      {/*=============== POST-ITEM ===============*/}
      <div className="col-12 col-md-4 post-item" >
        <div className="prod-thumbnail" >
          <a href="blog.html" >
            <img
              className="rx-lazy rx-lazy_item"
              src="img/blog-img-3.jpg"
              data-src="img/blog-img-3.jpg"
              alt="img"
              
            />
          </a>
        </div>
        <div className="post-content" >
          <div className="meta top" >
            <span className="post-by" >
              By{" "}
              <a href="#" >
                Sam Filips
              </a>{" "}
              /
            </span>
            <span className="post-date" >
              September 2019
            </span>
          </div>
          <h5 className="title" >
            <a href="blog.html" >
              Sed ut perspiciatis unde omnis iste natus error sit
            </a>
          </h5>
          <div className="meta bottom" >
            <span className="post-comments" >
              <a href="#" >
                Comments 2
              </a>
            </span>
            <span className="post-tags" >
              Tags:{" "}
              <a href="#" >
                Lorem,{" "}
              </a>
              <a href="#" >
                Drones
              </a>
            </span>
          </div>
          <a href="blog.html" className="btn" >
            more
          </a>
        </div>
      </div>
      {/*============= POST-ITEM END =============*/}
    </div>
  </div>
</section>

    </>
  )
}

export default LatestNews