/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Layout from "../components/Layout/Layout";

const About = () => {
  return (
    <Layout>
      <div
        className="page-title"
        style={{ backgroundImage: 'url("/img/bg-page.jpg")' }}
      >
        <div className="container">
          <h1 className="title">about us</h1>
        </div>
      </div>

      <section className="about-top">
        <div className="container">
          <div className="row about-top-cover">
            <div className="col-12 col-lg-6 about-img">
              <img src="/img/img-about-top.jpg" alt="img" />
            </div>
            <div className="col-12 col-lg-6 about-info">
              <div className="price-info">
                <h2 className="title-left">What is Immsy?</h2>
                <p>
                  Immsy is an innovative company dedicated to enhancing holistic
                  well-being through personalized digital and physical
                  experiences with our advanced AI wearables.
                </p>
              </div>
              <div className="price-info">
                <h2 className="title-left">Our Mission</h2>
                <p>
                  Enhancing holistic well-being through personalized digital and
                  physical experiences with our advanced AI wearables.
                </p>
              </div>
              <div className="price-info">
                <h2 className="title-left">Our Vision</h2>
                <p>
                  Create an ecosystem where the physical, mental, and emotional
                  well-being of users is at the core of the experience.
                </p>
              </div>
              <div className="price-info">
                <h2 className="title-left">Our Ambition</h2>
                <p>Make wellness accessible to everyone.</p>
              </div>
            </div>
          </div>
          <div className="row align-items-center my-5">
            <div className="col-12 col-md-6">
              <div className="price-info">
                <h2 className="title-left">Why Choose Immsy</h2>
                <p>
                  Discover why Immsy will be the ideal choice for your
                  well-being.
                </p>
              </div>
              <div className="price-info">
                <h2 className="title-left">Comprehensive Solutions:</h2>
                <p>
                  Offering personalized programs that combine digital and
                  physical experiences.
                </p>
              </div>
              <div className="price-info">
                <h2 className="title-left">Personalized Programs:</h2>
                <p>
                  Using AI to create wellness programs tailored to individual
                  needs.
                </p>
              </div>
              <div className="price-info">
                <h2 className="title-left">Stress Reduction:</h2>
                <p>
                  Proven techniques to reduce stress and improve mental health.
                </p>
              </div>
              <div className="price-info">
                <h2 className="title-left">Affordable Options:</h2>
                <p>Different pricing plans to fit all budgets.</p>
              </div>
              <div className="price-info">
                <h2 className="title-left">Immersive Experiences:</h2>
                <p>
                  Planned retreats and in-person programs for a holistic
                  wellness experience.
                </p>
              </div>
            </div>
            <div className="price-img-block col-md-6">
              <div className="price-img-cover">
                <img
                  className="rx-lazy rx-lazy_item"
                  src="https://html.rovadex.com/html-quadex/img/best-img.jpg"
                  data-src="https://html.rovadex.com/html-quadex/img/best-img.jpg"
                  alt="img"
                />
                <img
                  className="price-drone rx-lazy rx-lazy_item"
                  src="/img/drone-1.png"
                  data-src="/img/drone-1.png"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default About;
