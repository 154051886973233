/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Layout from "../components/Layout/Layout";

const Community = () => {
  return (
    <>
      <Layout>
        <div
          className="page-title"
          style={{ backgroundImage: 'url("/img/bg-page.jpg")' }}
        >
          <div className="container">
            <h1 className="title">Community</h1>
          </div>
        </div>

        <section className="new-product" >
          <div className="container" >
            <h2 >Community Engagement</h2>
            <div
              className="slider-new-product slick-initialized slick-slider"
            >
              {/*========== SLIDE-NEW-PRODUCT ==========*/}
              <div
                aria-live="polite"
                className="slick-list draggable container px-5"
              >
                <div
                  className="slick-track row"
                >
                  <div
                    className="slide-new-product slick-slide slick-cloned col-md-3"
                  >
                    {/*============= PROD-ITEM =============*/}
                    <div className="prod-item new" >
                      <div
                        className="prod-thumbnail"
                        
                      >
                        <div className="prod-img" >
                          <div
                            className="lable lable-new"
                            
                          >
                            new
                          </div>
                          <div
                            className="lable lable-sale"
                            
                          >
                            sale
                          </div>
                          <img
                            src="/img/prod-2.jpg"
                            alt="/img"
                            
                          />
                          <ul
                            className="prod-link-cover"
                            
                          >
                            <li >
                              <a
                                href="javascript:void(0)"
                                tabIndex={-1}
                                
                              >
                                <i
                                  className="fa fa-link"
                                  aria-hidden="true"
                                  
                                />
                              </a>
                            </li>
                            <li >
                              <a
                                href="#"
                                tabIndex={-1}
                                
                              >
                                <i
                                  className="fa fa-exchange"
                                  aria-hidden="true"
                                  
                                />
                              </a>
                            </li>
                            <li >
                              <a
                                href="#"
                                tabIndex={-1}
                                
                              >
                                <i
                                  className="fa fa-heart"
                                  aria-hidden="true"
                                  
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="prod-content" >
                        <h5 className="title" >
                          <a
                            href="javascript:void(0)"
                            tabIndex={-1}
                            
                          >
                            Community Engagement
                          </a>
                        </h5>
                        <p >
                        Live interactions with experts to answer wellness questions.
                        </p>

                      </div>
                    </div>
                    {/*=========== PROD-ITEM END ===========*/}
                  </div>
                  <div
                    className="slide-new-product slick-slide slick-cloned col-md-3"
                  >
                    {/*============= PROD-ITEM =============*/}
                    <div className="prod-item new" >
                      <div
                        className="prod-thumbnail"
                        
                      >
                        <div className="prod-img" >
                          <div
                            className="lable lable-new"
                            
                          >
                            new
                          </div>
                          <div
                            className="lable lable-sale"
                            
                          >
                            sale
                          </div>
                          <img
                            src="/img/prod-3.jpg"
                            alt="/img"
                            
                          />
                          <ul
                            className="prod-link-cover"
                            
                          >
                            <li >
                              <a
                                href="javascript:void(0)"
                                tabIndex={-1}
                                
                              >
                                <i
                                  className="fa fa-link"
                                  aria-hidden="true"
                                  
                                />
                              </a>
                            </li>
                            <li >
                              <a
                                href="#"
                                tabIndex={-1}
                                
                              >
                                <i
                                  className="fa fa-exchange"
                                  aria-hidden="true"
                                  
                                />
                              </a>
                            </li>
                            <li >
                              <a
                                href="#"
                                tabIndex={-1}
                                
                              >
                                <i
                                  className="fa fa-heart"
                                  aria-hidden="true"
                                  
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="prod-content" >
                        <h5 className="title" >
                          <a
                            href="javascript:void(0)"
                            tabIndex={-1}
                            
                          >
                           Events and Workshops:
                          </a>
                        </h5>
                        <p >
                        Organization of webinars, live workshops, and community events.
                        </p>
                      </div>
                    </div>
                    {/*=========== PROD-ITEM END ===========*/}
                  </div>
                  <div
                    className="slide-new-product slick-slide slick-cloned col-md-3"
                  >
                    {/*============= PROD-ITEM =============*/}
                    <div className="prod-item new" >
                      <div
                        className="prod-thumbnail"
                        
                      >
                        <div className="prod-img" >
                          <div
                            className="lable lable-new"
                            
                          >
                            new
                          </div>
                          <div
                            className="lable lable-sale"
                            
                          >
                            sale
                          </div>
                          <img
                            src="/img/prod-4.jpg"
                            alt="/img"
                            
                          />
                          <ul
                            className="prod-link-cover"
                            
                          >
                            <li >
                              <a
                                href="javascript:void(0)"
                                tabIndex={-1}
                                
                              >
                                <i
                                  className="fa fa-link"
                                  aria-hidden="true"
                                  
                                />
                              </a>
                            </li>
                            <li >
                              <a
                                href="#"
                                tabIndex={-1}
                                
                              >
                                <i
                                  className="fa fa-exchange"
                                  aria-hidden="true"
                                  
                                />
                              </a>
                            </li>
                            <li >
                              <a
                                href="#"
                                tabIndex={-1}
                                
                              >
                                <i
                                  className="fa fa-heart"
                                  aria-hidden="true"
                                  
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="prod-content" >
                        <h5 className="title" >
                          <a
                            href="javascript:void(0)"
                            tabIndex={-1}
                            
                          >
                            Wellness Challenges:
                          </a>
                        </h5>
                        <p >
                        Participation in challenges to motivate and track progress.
                        </p>
                      </div>
                    </div>
                    {/*=========== PROD-ITEM END ===========*/}
                  </div>
                  <div
                    className="slide-new-product slick-slide slick-cloned col-md-3"
                  >
                    {/*============= PROD-ITEM =============*/}
                    <div className="prod-item new" >
                      <div
                        className="prod-thumbnail"
                        
                      >
                        <div className="prod-img" >
                          <div
                            className="lable lable-new"
                            
                          >
                            new
                          </div>
                          <div
                            className="lable lable-sale"
                            
                          >
                            sale
                          </div>
                          <img
                            src="/img/prod-5.jpg"
                            alt="/img"
                            
                          />
                          <ul
                            className="prod-link-cover"
                            
                          >
                            <li >
                              <a
                                href="javascript:void(0)"
                                tabIndex={-1}
                                
                              >
                                <i
                                  className="fa fa-link"
                                  aria-hidden="true"
                                  
                                />
                              </a>
                            </li>
                            <li >
                              <a
                                href="#"
                                tabIndex={-1}
                                
                              >
                                <i
                                  className="fa fa-exchange"
                                  aria-hidden="true"
                                  
                                />
                              </a>
                            </li>
                            <li >
                              <a
                                href="#"
                                tabIndex={-1}
                                
                              >
                                <i
                                  className="fa fa-heart"
                                  aria-hidden="true"
                                  
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="prod-content" >
                        <h5 className="title" >
                          <a
                            href="javascript:void(0)"
                            tabIndex={-1}
                            
                          >
                           Q&A Sessions:
                          </a>
                        </h5>
                        <p >
                        Live interactions with experts to answer wellness questions.
                        </p>

                      </div>
                    </div>
                    {/*=========== PROD-ITEM END ===========*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Community;
