/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/role-has-required-aria-props */
import React, { useEffect, useState } from "react";

const HomeHeroSection = () => {
  const images = [
    "/img/home1-slide10.jpg",
    "/img/home1-slide4.jpg",
    "/img/home1-slide5.jpg",
    "/img/home1-slide12.jpg",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const slides = document.querySelectorAll(".slider-background");
    slides.forEach((slide, index) => {
      slide.classList.toggle("active", index === currentIndex);
    });
  }, [currentIndex]);

  return (
    <>
      <main className="container-fluid px-0">
        <section className="main-slider-cover">
          <div className="main-slider">
            <div className="main-slider-for">
              {images.map((image, index) => (
                <div
                  key={index}
                  className={`slider-background ${
                    index === currentIndex ? "active" : ""
                  }`}
                  style={{ backgroundImage: `url(${image})` }}
                >
                  {/* Optional content for each slide */}
                </div>
              ))}
            </div>
            <div className="slide-info">
              <h3>Do good For Others</h3>
              <p>
                We help nonprofits from Afghanistan to Zimbabwe (and hundreds of
                places in between) access the tools, training,
              </p>
              <a href="javascript:void(0)" className="btn">
                join the journey
              </a>
            </div>

            <div className="main-slider-nav">
              <div className="thumbnail-container">
                {images.map((image, index) => (
                  <div
                    key={index}
                    className={`thumbnail ${
                      index === currentIndex ? "active" : ""
                    }`}
                    onClick={() => handleClick(index)}
                  >
                    <img src={image} alt={`Thumbnail ${index + 1}`} />
                  </div>
                ))}
              </div>
              <div className="d-flex">
                <span className="slick-arrow prev" onClick={prevSlide}>
                  <i className="fa fa-angle-left" aria-hidden="true" />
                </span>
                <span
                  className="slick-arrow next"
                  style={{ left: "150px" }}
                  onClick={nextSlide}
                >
                  <i className="fa fa-angle-right" aria-hidden="true" />
                </span>
              </div>
            </div>
          </div>
        </section>

        <h2 className="mt-5">Frequently Asked Questions (FAQ)</h2>
        <section className="serv-block row mx-0 my-5">
          <a href="javascript:void(0)" className="serv-block-item col-md-4 p-0">
            <span className="border-item" />
            <img
              className="rx-lazy rx-lazy_item"
              src="/img/img-1.jpg"
              data-src="/img/img-1.jpg"
              alt="img"
            />
            <div className="serv-block-info">
              <h4>What is Immsy?</h4>
              <p>
                Immsy is an online platform in development that aims to promote
                holistic well-being by offering personalized wellness programs,
                immersive retreats, and AI-driven recommendations tailored to
                individual needs.
              </p>
            </div>
          </a>
          <a href="javascript:void(0)" className="serv-block-item col-md-4 p-0">
            <span className="border-item" />
            <img
              className="rx-lazy rx-lazy_item"
              src="/img/img-2.jpg"
              data-src="/img/img-2.jpg"
              alt="img"
            />
            <div className="serv-block-info">
              <h4>Are there any live events or retreats offered by Immsy?</h4>
              <p>
                Immsy plans to offer live events and immersive retreats in the
                future. Check our retreats page for more details.
              </p>
            </div>
          </a>
          <a href="javascript:void(0)" className="serv-block-item col-md-4 p-0">
            <span className="border-item" />
            <img
              className="rx-lazy rx-lazy_item"
              src="/img/img-3.jpg"
              data-src="/img/img-3.jpg"
              alt="img"
            />
            <div className="serv-block-info">
              <h4>How can I access Immsy’s services?</h4>
              <p>
                You will be able to access our services by subscribing to one of
                our membership plans available on our website.
              </p>
            </div>
          </a>
          <a href="javascript:void(0)" className="serv-block-item col-md-4 p-0">
            <span className="border-item" />
            <img
              className="rx-lazy rx-lazy_item"
              src="/img/blog-img-2.jpg"
              data-src="/img/blog-img-2.jpg"
              alt="img"
            />
            <div className="serv-block-info">
              <h4>How does Immsy personalize wellness programs?</h4>
              <p>
                Immsy will use AI technology to tailor programs based on
                individual preferences, goals, and progress.
              </p>
            </div>
          </a>
          <a href="javascript:void(0)" className="serv-block-item col-md-4 p-0">
            <span className="border-item" />
            <img
              className="rx-lazy rx-lazy_item"
              src="/img/blog-img-1.jpg"
              data-src="/img/blog-img-1.jpg"
              alt="img"
            />
            <div className="serv-block-info">
              <h4>Is there a free trial available for Immsy’s services?</h4>
              <p>
                We plan to offer a free trial period for new users to explore
                our services.
              </p>
            </div>
          </a>
          <a href="javascript:void(0)" className="serv-block-item col-md-4 p-0">
            <span className="border-item" />
            <img
              className="rx-lazy rx-lazy_item"
              src="/img/img-4.jpg"
              data-src="/img/img-4.jpg"
              alt="img"
            />
            <div className="serv-block-info">
              <h4>What types of wellness programs will Immsy offer?</h4>
              <p>
                Immsy will offer a wide range of programs including fitness,
                yoga, meditation, nutrition, and personal development.
              </p>
            </div>
          </a>
        </section>
        {/*============= S-CLIENTS END =============*/}
      </main>
    </>
  );
};

export default HomeHeroSection;
