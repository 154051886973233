/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row px-5">
            <div className="col-6 col-sm-4 col-lg-3 blok-link">
              <h5>Home</h5>
              <ul>
                <li>
                  <Link to={"/"}>Home</Link>
                </li>
                <li>
                  <Link to={"/about"}>About</Link>
                </li>
                <li>
                  <Link to={"/library"}>Programs</Link>
                </li>
                <li>
                  <Link to={"/restreat"}>Retreats</Link>
                </li>
              </ul>
            </div>
            <div className="col-6 col-sm-4 col-lg-3 blok-link">
              <h5>Programs</h5>
              <ul>
                <li>
                  <Link to={"/library"}>Library Overview</Link>
                </li>
                <li>
                  <Link to={"/profile"}>User Profiles</Link>
                </li>
                <li>
                  <Link to={"/program"}>Programs</Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-4 col-lg-3 blok-link">
              <h5>Quick LInks</h5>
              <ul>
                <li>
                  <Link to={"/wearable"}>AI Wearables</Link>
                </li>
                <li>
                  <Link to={"/community"}>Community</Link>
                </li>
                <li>
                  <Link to={"/social"}>Social Awareness</Link>
                </li>
                <li>
                  <Link to={"/contact"}>Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 footer-subscribe">
              <h5>subscribe</h5>
              <form
                action="https://html.rovadex.com/"
                className="subscribe-form"
              >
                <input type="email" name="subscribe" placeholder="E-mail" />
                <input type="submit" defaultValue="send" />
              </form>
            </div>
          </div>
          <div className="copyright">
            <p>
              <a className="rovadex" href="https://rovadex.com/">
                HNH TECH SOLUTIONS
              </a>{" "}
              © 2022. <a href="javascript:void(0)">All Rights Reserved.</a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
