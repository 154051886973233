/* eslint-disable jsx-a11y/role-has-required-aria-props */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const Testimonial = () => {

  const testimonials = [
    {
      imgSrc: "img/testimonials-2.png",
      name: "Sandra Gibbs",
      profession: "clients",
      content:
        "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores",
    },
    {
      imgSrc: "img/testimonials-3.jpg",
      name: "Anna Smith",
      profession: "clients",
      content:
        "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores",
    },
    {
      imgSrc: "img/testimonials-1.png",
      name: "Sam Piters",
      profession: "clients",
      content:
        "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores",
    },
    {
      imgSrc: "img/testimonials-2.png",
      name: "Sandra Gibbs",
      profession: "clients",
      content:
        "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores",
    },
  ];

  return (
    <>
      <section className="s-testimonials">
        <div className="container s-anim s-anim-active">
          <h2>Testimonials</h2>
          <p className="slogan">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud.
          </p>
          <Swiper
            modules={[Pagination]}
            spaceBetween={30}
            slidesPerView={2}
            pagination={{ clickable: true }}
            className="swiper-container"
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide
                key={index}
                className="slide-testimonial slick-slide slick-cloned"
              >
                <div className="testimonial-item">
                  <img
                    className="rx-lazy rx-lazy_item"
                    src={testimonial.imgSrc}
                    alt={testimonial.name}
                  />
                  <h5 className="name">{testimonial.name}</h5>
                  <div className="prof">{testimonial.profession}</div>
                  <div className="testimon-content">{testimonial.content}</div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
