import React from "react";
import Layout from "../components/Layout/Layout";

const Retreats = () => {
  return (
    <>
      <Layout>
        <div
          className="page-title"
          style={{ backgroundImage: 'url("img/bg-page.jpg")' }}
        >
          <div className="container">
            <h1 className="title">Retreats</h1>
          </div>
        </div>
        <section className="s-we-offer">
          <div className="container">
            <h2>Retreats</h2>
            <div className="row we-offer-cover">
              <div className="col-12 col-sm-6 we-offer-item">
                <div className="offer-item-img">
                  <img src="/img/img-serv-1.jpg" alt="img" />
                </div>
                <div className="offer-item-content">
                    <div className="serviceDetailsContent">
                      <h6 className="serviceDetailsContent__heading my-4">
                        Upcoming Immersive Wellness Retreats
                      </h6>
                      <p>
                        Immsy plans to offer unique retreats combining yoga,
                        meditation, fitness, and nutrition programs in natural
                        and serene settings. Our retreat centers, planned for
                        2028, will provide an immersive holistic wellness
                        experience.
                      </p>
                      <h6 className="serviceDetailsContent__heading my-4">
                        Experience Ultimate Wellness and Relaxation at Our
                        Exclusive Retreats
                      </h6>
                      <h6 className="serviceDetailsContent__heading my-4">
                        Immsy Retreat (Wellness Retreats)
                      </h6>
                      <p>Title: Immsy Retreats</p>
                      <p>Subtitle: Wellness Retreats</p>
                      <p>
                        Description: Our wellness retreats are designed to
                        provide a transformative experience that integrates
                        advanced health monitoring technologies with holistic
                        wellness programs. Located in serene, picturesque
                        locations, our retreats offer the perfect environment
                        for rejuvenation and self-discovery.
                      </p>
                      <h6 className="serviceDetailsContent__heading my-4">
                        Program Details
                      </h6>
                      <h6 className="serviceDetailsContent__heading my-4">
                        Immsy Retreats
                      </h6>
                    </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 we-offer-item">
                <div className="offer-item-img">
                  <img src="/img/img-serv-2.jpg" alt="img" />
                </div>
                <div className="offer-item-content">
                  <div className="serviceDetailsContent">
                    <h6 className="serviceDetailsContent__heading mb-20 mt-20">
                      Features:
                    </h6>
                    <ul className="ps-4">
                      <li style={{ listStyle: "disc" }}>
                        Advanced Health Monitoring: Utilize ImmsyFit and
                        NeuroLink technologies to track and optimize your health
                        during your stay.
                      </li>
                      <li style={{ listStyle: "disc" }}>
                        Personalized Wellness Programs: Receive customized
                        wellness plans created by Immsy Assistant, tailored to
                        your specific needs and goals.
                      </li>
                      <li style={{ listStyle: "disc" }}>
                        Holistic Therapies: Enjoy a range of therapies,
                        including yoga, meditation, spa treatments, and
                        nutrition counseling.
                      </li>
                      <li style={{ listStyle: "disc" }}>
                        Comfortable Accommodations: Stay in comfortable
                        accommodations designed for relaxation.
                      </li>
                      <li style={{ listStyle: "disc" }}>
                        Gourmet Healthy Cuisine: Savor nutritious and delicious
                        meals prepared by our expert chefs, using locally
                        sourced organic ingredients.
                      </li>
                      <li style={{ listStyle: "disc" }}>
                        Scenic and Serene Locations: Retreats located in
                        tranquil environments, perfect for relaxation and
                        reflection.
                      </li>
                      <li style={{ listStyle: "disc" }}>
                        Wellness Workshops: Participate in workshops and
                        seminars on wellness, mindfulness, and personal growth.
                      </li>
                    </ul>
                    <h6 className="serviceDetailsContent__heading mb-20 mt-20">
                      Benefits:
                    </h6>
                    <ul className="ps-4">
                      <li style={{ listStyle: "disc" }}>
                        Comprehensive Health Insights: Benefit from real-time
                        health data and personalized recommendations to improve
                        your well-being.
                      </li>
                      <li style={{ listStyle: "disc" }}>
                        Stress Reduction and Relaxation: Engage in activities
                        and therapies designed to reduce stress and promote
                        relaxation.
                      </li>
                      <li style={{ listStyle: "disc" }}>
                        Holistic Approach to Wellness: Experience a well-rounded
                        wellness program that addresses physical, mental, and
                        emotional health.
                      </li>
                      <li style={{ listStyle: "disc" }}>
                        Personal Growth and Development: Gain new insights and
                        skills through our wellness workshops and personalized
                        guidance.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Retreats;
