/* eslint-disable jsx-a11y/role-has-required-aria-props */
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const NewOffer = () => {
  const opportunities = [
    {
      title: "Mavic Pro 2",
      specs: [
        { value: "55", unit: "km/h", label: "drone speed" },
        { value: "3", unit: "km", label: "connection drone" },
        { value: "15-20", unit: "min", label: "battery charge" },
        { value: "500", unit: "gm", label: "weight drone" },
      ],
    },
    {
      title: "Phantom 4 Pro",
      specs: [
        { value: "3", unit: "km", label: "connection drone" },
        { value: "15-20", unit: "min", label: "battery charge" },
        { value: "55", unit: "km/h", label: "drone speed" },
        { value: "500", unit: "gm", label: "weight drone" },
      ],
    },
  ];

  return (
    <>
      <section className="s-opportunities">
        <div className="container">
          <h2>New Offers</h2>
          <p className="slogan">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud.
          </p>
          <Swiper
            modules={[Navigation]}
            spaceBetween={30}
            slidesPerView={1}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            className="swiper-container"
          >
            {opportunities.map((opportunity, index) => (
              <SwiperSlide key={index}>
                <div className="slider-opportunities">
                  <h3 className="title">{opportunity.title}</h3>
                  <div className="opportunities-cover row">
                    {opportunity.specs.map((spec, i) => (
                      <div className="col-6 col-md-3">
                        <div className="opportunitie-item">
                          <div className="item-wrap">
                            <div className="numb">{spec.value}</div>
                            <span className="item-value">{spec.unit}</span>
                            <h6>{spec.label}</h6>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <a href="shop-single.html" className="btn">
                    View More
                  </a>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* Add navigation buttons */}
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </div>
      </section>
    </>
  );
};

export default NewOffer;
