/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import Layout from "../components/Layout/Layout";

const ContactUs = () => {
  return (
    <>
      <Layout>
        <div
          className="page-title"
          style={{ backgroundImage: 'url("/img/bg-page.jpg")' }}
        >
          <div className="container">
            <h1 className="title">Contact us</h1>
          </div>
        </div>
        <section className="s-contacts s-main-contacts mt-5 pb-0">
          <div className="container">
            <h2>Contact us</h2>
            <p className="slogan">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmmpor incididunt ut labore et dolore magna aliqua. Ut enim ad
              minim veniam, quis nostrud.
            </p>
            <form id="contactform" action="php/contact.php" name="contactform">
              <ul className="form-cover">
                <li className="inp-name">
                  <input
                    id="name"
                    type="text"
                    name="your-name"
                    placeholder="Name"
                  />
                </li>
                <li className="inp-phone">
                  <input
                    id="phone"
                    type="tel"
                    name="your-phone"
                    placeholder="Phone"
                  />
                </li>
                <li className="inp-email">
                  <input
                    id="email"
                    type="email"
                    name="your-email"
                    placeholder="E-mail"
                  />
                </li>
                <li className="inp-text">
                  <textarea
                    id="comments"
                    name="your-text"
                    placeholder="Message"
                    defaultValue={""}
                  />
                </li>
              </ul>
              <div className="checkbox-wrap">
                <div className="checkbox-cover">
                  <input type="checkbox" />
                  <p>
                    By using this form you agree with the storage and handling
                    of your data by this website.
                  </p>
                </div>
              </div>
              <div className="btn-form-cover">
                <button id="#submit" type="submit" className="btn">
                  submit comment
                </button>
              </div>
            </form>
            <div id="message" />
          </div>
          <div className="map mt-4" style={{ height: "80vh" }}>
            <iframe
              width={"100%"}
              height={"100%"}
              src="//maps.google.com/maps?q=53.3381768,-6.2613077&z=15&output=embed"
            ></iframe>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default ContactUs;
