import React from "react";
import Layout from "../components/Layout/Layout";

const AIWereables = () => {
  return (
    <>
      <Layout>
        <div
          className="page-title"
          style={{ backgroundImage: 'url("/img/bg-page.jpg")'}}
        >
          <div className="container">
            <h1 className="title">AI Wearables</h1>
          </div>
        </div>

        <section className="s-pravicy-policy px-5">
          <div className="container">
            <h3>Our Latest Features</h3>
            <p>
              AI Technology: Utilizing AI to create personalized wellness
              programs tailored to individual needs.
            </p>
            <h3>Immsy Assistant (ImBot)</h3>
            <p>Title: Immsy Assistant</p>
            <p>Subtitle: ImBot</p>
            <p>
              Description: The Immsy Assistant is a sophisticated chatbot
              powered by GPT, designed to guide users through their wellness
              programs, create customized plans based on connected device data
              analysis, answer health-related questions, and ensure overall user
              safety and well-being.
            </p>
            <p>
              We are currently in the Research & Development phase on innovative
              devices that will revolutionize wellness.
            </p>
            <h3>ImmsyFit (Smart Patch)</h3>
            <p>Title: ImmsyFit</p>
            <p>Subtitle: Im Fit</p>
            <p>
              Description: A cutting-edge portable device for real-time health
              monitoring, integrating IoT connectivity and featuring an
              intelligent personal assistant for advanced health management.
            </p>
            <p>
              Personalized Programs: Programs specifically designed for you,
              based on your preferences and goals.
            </p>
            <p>
              Immersive Retreats: Combining digital content with in-person
              retreat experiences for a holistic wellness approach.
            </p>
            <h3>Wearable and Neural Technologies</h3>
            <p>
              Immsy aims to be at the forefront of developing advanced
              AI-powered wearable technologies that monitor and enhance
              physical, mental, and emotional well-being. We aim to innovate in
              neural technologies to provide deeper insights and more effective
              wellness solutions.
            </p>
            <h3>AI-Powered Wearable Technologies</h3>
            <p>
              We aim to specialize in AI-powered wearable technologies that
              offer real-time health monitoring and personalized recommendations
              on connected devices and our platform. We will integrate augmented
              reality (AR) and virtual reality (VR) experiences to enhance user
              engagement and the effectiveness of our wellness programs.
            </p>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default AIWereables;
