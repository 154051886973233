/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Layout from "../components/Layout/Layout";

const Programs = () => {
  return (
    <>
      <Layout>
        <div
          className="page-title"
          style={{ backgroundImage: 'url("/img/bg-page.jpg")' }}
        >
          <div className="container">
            <h1 className="title">Programs</h1>
          </div>
        </div>
        <section className="s-news">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-12 blog-cover">
                <div className="post-item-cover row">
                  <div className="post-header col-md-6">
                    <div className="post-thumbnail">
                      <a href="javascript:void(0)">
                        <img src="/img/blog-img-1.jpg" alt="img" />
                      </a>
                    </div>
                  </div>
                  <div className="post-content col-md-6">
                    <div className="serviceDetailsContent">
                      <h6 className="serviceDetailsContent__heading mb-20  mt-20">
                        Mental Health
                      </h6>
                      <ul className="ps-4">
                        <li style={{ listStyle: "disc" }}>
                          Resources for stress management and mental health
                          tracking: Helps manage stress and monitor mental
                          health status.
                        </li>
                        <li style={{ listStyle: "disc" }}>
                          Integration with mental and physical health
                          professionals for personalized AI-driven care: Offers
                          personalized support through data analysis and
                          professional advice.
                        </li>
                      </ul>
                      <h6 className="serviceDetailsContent__heading mb-20  mt-20">
                        Nutrition
                      </h6>
                      <ul className="ps-4">
                        <li style={{ listStyle: "disc" }}>
                          Personalized nutrition plans and food tracking: Helps
                          users maintain a healthy and balanced diet.
                        </li>
                        <li style={{ listStyle: "disc" }}>
                          Integration with food scanners and diet tracking apps:
                          Facilitates tracking of eating habits and diet
                          management.
                        </li>
                      </ul>
                      <h6 className="serviceDetailsContent__heading mb-20  mt-20">
                        Education
                      </h6>
                      <ul className="ps-4">
                        <li style={{ listStyle: "disc" }}>
                          Resources and wellness tracking for NGOs: Supports
                          wellness initiatives within non-governmental
                          organizations.
                        </li>
                        <li style={{ listStyle: "disc" }}>
                          Integration with programs for underprivileged areas:
                          Provides wellness solutions to underserved
                          communities.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="post-item-cover row">
                  <div className="post-header col-md-6">
                    <div className="post-thumbnail">
                      <a href="javascript:void(0)">
                        <img src="/img/blog-img-2.jpg" alt="img" />
                      </a>
                    </div>
                  </div>
                  <div className="post-content col-md-6">
                    <div className="serviceDetailsContent">
                      <h6 className="serviceDetailsContent__heading mb-20  mt-20">
                        Healthcare
                      </h6>
                      <ul className="ps-4">
                        <li style={{ listStyle: "disc" }}>
                          Integration with medical devices and health monitoring
                          technologies: Enables precise, real-time tracking of
                          various health parameters.
                        </li>
                        <li style={{ listStyle: "disc" }}>
                          Personalized health recommendations and information:
                          Provides tailored advice based on user health data,
                          generated by our AI platform.
                        </li>
                      </ul>
                      <h6 className="serviceDetailsContent__heading mb-20  mt-20">
                        Fitness and Wellness
                      </h6>
                      <ul className="ps-4">
                        <li style={{ listStyle: "disc" }}>
                          Fitness tracking and personalized workout plans: Helps
                          users achieve their fitness goals through customized
                          programs.
                        </li>
                        <li style={{ listStyle: "disc" }}>
                          Integration with wearable devices: Ensures seamless
                          compatibility with wearables for continuous tracking.
                        </li>
                      </ul>
                      <h6 className="serviceDetailsContent__heading mb-20  mt-20">
                        Corporate Wellness
                      </h6>
                      <ul className="ps-4">
                        <li style={{ listStyle: "disc" }}>
                          Wellness programs for employees and progress tracking:
                          Promotes health and well-being within companies.
                        </li>
                        <li style={{ listStyle: "disc" }}>
                          Employee health dashboards: Provide tools for
                          monitoring and analyzing employee health data.
                        </li>
                      </ul>
                      <h6 className="serviceDetailsContent__heading mb-20  mt-20">
                        Sports and Athletics
                      </h6>
                      <ul className="ps-4">
                        <li style={{ listStyle: "disc" }}>
                          Tracking athletic performance and tailored training
                          programs: Helps athletes optimize their performance.
                        </li>
                        <li style={{ listStyle: "disc" }}>
                          Analyses and insights for professional athletes:
                          Provides data and advice to improve performance.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Programs;
