/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const OurProduct = () => {
  return (
    <>
      <section className="s-shop">
        <div className="container s-anim s-anim-active">
          <h2>our products</h2>
          <p className="slogan">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmmpor incididunt ut labore et dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud.
          </p>
          {/*================== TAB-WRAP ==================*/}
          <div className="tab-wrap">
            <ul className="tab-nav prod-tabs">
              <li className="item active" rel="tab1">
                <div className="block-icon">
                  <img src="img/icon-tab-1.svg" alt="img" />
                  <h6>drones</h6>
                </div>
              </li>
              <li className="item" rel="tab2">
                <div className="block-icon">
                  <img src="img/icon-tab-2.svg" alt="img" />
                  <h6>accessories</h6>
                </div>
              </li>
              <li className="item" rel="tab3">
                <div className="block-icon">
                  <img src="img/icon-tab-3.svg" alt="img" />
                  <h6>electronics</h6>
                </div>
              </li>
              <li className="item" rel="tab4">
                <div className="block-icon">
                  <img src="img/icon-tab-4.svg" alt="img" />
                  <h6>cameras</h6>
                </div>
              </li>
              <li className="item" rel="tab5">
                <div className="block-icon">
                  <img src="img/icon-tab-5.svg" alt="img" />
                  <h6>batteries</h6>
                </div>
              </li>
              <li className="item" rel="tab6">
                <div className="block-icon">
                  <img src="img/icon-tab-6.svg" alt="img" />
                  <h6>Remote Control</h6>
                </div>
              </li>
            </ul>
            <div className="tabs-content" style={{ height: 1634 }}>
              {/*================== TAB1 ==================*/}
              <div className="tab tab1 visible-content">
                <div className="row prod-cover">
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-1.jpg"
                          data-src="img/prod-1.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Inspire pro Camera</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="old-price">399$</span>
                        <span className="new-price">299$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-2.jpg"
                          data-src="img/prod-2.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic 2 Pro with</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">599$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-3.jpg"
                          data-src="img/prod-3.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic 2 Hasselblad</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">499$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-4.jpg"
                          data-src="img/prod-4.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic 2 zoom</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="old-price">399$</span>
                        <span className="new-price">299$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-5.jpg"
                          data-src="img/prod-5.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic pro Camera</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">599$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-6.jpg"
                          data-src="img/prod-6.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Phantom 4 Pro</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">499$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                </div>
              </div>
              {/*================ TAB1 END ================*/}
              {/*================== TAB2 ==================*/}
              <div className="tab tab2">
                <div className="row prod-cover">
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-4.jpg"
                          data-src="img/prod-4.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic 2 zoom</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="old-price">399$</span>
                        <span className="new-price">299$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-5.jpg"
                          data-src="img/prod-5.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic pro Camera</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">599$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-6.jpg"
                          data-src="img/prod-6.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Phantom 4 Pro</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">499$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-1.jpg"
                          data-src="img/prod-1.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Inspire pro Camera</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="old-price">399$</span>
                        <span className="new-price">299$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-2.jpg"
                          data-src="img/prod-2.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic 2 Pro with</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">599$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-3.jpg"
                          data-src="img/prod-3.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic 2 Hasselblad Camera</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">499$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                </div>
              </div>
              {/*================ TAB2 END ================*/}
              {/*================== TAB3 ==================*/}
              <div className="tab tab3">
                <div className="row prod-cover">
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-1.jpg"
                          data-src="img/prod-1.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Inspire pro Camera</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="old-price">399$</span>
                        <span className="new-price">299$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-2.jpg"
                          data-src="img/prod-2.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic 2 Pro with</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">599$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-3.jpg"
                          data-src="img/prod-3.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic 2 Hasselblad Camera</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">499$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-4.jpg"
                          data-src="img/prod-4.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic 2 zoom</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="old-price">399$</span>
                        <span className="new-price">299$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-5.jpg"
                          data-src="img/prod-5.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic pro Camera</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">599$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-6.jpg"
                          data-src="img/prod-6.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Phantom 4 Pro</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">499$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                </div>
              </div>
              {/*================ TAB3 END ================*/}
              {/*================== TAB4================== */}
              <div className="tab tab4">
                <div className="row prod-cover">
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-4.jpg"
                          data-src="img/prod-4.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic 2 zoom</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="old-price">399$</span>
                        <span className="new-price">299$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-5.jpg"
                          data-src="img/prod-5.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic pro Camera</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">599$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-6.jpg"
                          data-src="img/prod-6.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Phantom 4 Pro</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">499$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-1.jpg"
                          data-src="img/prod-1.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Inspire pro Camera</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="old-price">399$</span>
                        <span className="new-price">299$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-2.jpg"
                          data-src="img/prod-2.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic 2 Pro with</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">599$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-3.jpg"
                          data-src="img/prod-3.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic 2 Hasselblad Camera</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">499$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                </div>
              </div>
              {/*================ TAB4 END ================*/}
              {/*================== TAB5 ==================*/}
              <div className="tab tab5">
                <div className="row prod-cover">
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-1.jpg"
                          data-src="img/prod-1.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Inspire pro Camera</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="old-price">399$</span>
                        <span className="new-price">299$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-2.jpg"
                          data-src="img/prod-2.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic 2 Pro with</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">599$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-3.jpg"
                          data-src="img/prod-3.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic 2 Hasselblad Camera</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">499$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-4.jpg"
                          data-src="img/prod-4.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic 2 zoom</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="old-price">399$</span>
                        <span className="new-price">299$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-5.jpg"
                          data-src="img/prod-5.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic pro Camera</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">599$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-6.jpg"
                          data-src="img/prod-6.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Phantom 4 Pro</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">499$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                </div>
              </div>
              {/*================ TAB5 END ================*/}
              {/*================== TAB6 ==================*/}
              <div className="tab tab6">
                <div className="row prod-cover">
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-4.jpg"
                          data-src="img/prod-4.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic 2 zoom</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="old-price">399$</span>
                        <span className="new-price">299$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-5.jpg"
                          data-src="img/prod-5.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic pro Camera</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">599$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-6.jpg"
                          data-src="img/prod-6.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Phantom 4 Pro</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">499$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-1.jpg"
                          data-src="img/prod-1.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Inspire pro Camera</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="old-price">399$</span>
                        <span className="new-price">299$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-2.jpg"
                          data-src="img/prod-2.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic 2 Pro with</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">599$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                  {/* prod-item */}
                  <div className="col-12 col-sm-6 col-md-4 prod-item">
                    <div className="prod-thumbnail">
                      <div className="prod-img">
                        <div className="lable lable-new">new</div>
                        <div className="lable lable-sale">sale</div>
                        <img
                          className="rx-lazy rx-lazy_item"
                          src="img/prod-3.jpg"
                          data-src="img/prod-3.jpg"
                          alt="img"
                        />
                        <ul className="prod-link-cover">
                          <li>
                            <a href="shop-single.html">
                              <i className="fa fa-link" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-exchange"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-heart" aria-hidden="true" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prod-content">
                      <h5 className="title">
                        <a href="shop-single.html">Mavic 2 Hasselblad Camera</a>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmmpor incididunt ut labore et
                      </p>
                      <div className="price">
                        <span className="new-price">499$</span>
                      </div>
                      <a href="shop-single.html" className="btn">
                        buy now
                      </a>
                    </div>
                  </div>
                  {/* prod-item end */}
                </div>
              </div>
              {/*================ TAB6 END ================*/}
            </div>
          </div>
          {/*================ TAB-WRAP END ================*/}
        </div>
      </section>
    </>
  );
};

export default OurProduct;
