/* eslint-disable no-unused-vars */
// src/components/CustomCursor.js
import React, { useEffect, useState } from "react";

const CustomCursor = () => {
  const [cursorX, setCursorX] = useState(0);
  const [cursorY, setCursorY] = useState(0);
  const [isClicking, setIsClicking] = useState(false);
  const [buttonHovered, setButtonHovered] = useState(false);

  const move = (e) => {
    const touchEvent = e.touches ? e.touches[0] : null;
    const x = e.clientX || (touchEvent ? touchEvent.clientX : 0);
    const y = e.clientY || (touchEvent ? touchEvent.clientY : 0);

    setCursorX(x);
    setCursorY(y);

    const cursorBorder = document.getElementById("cursor-border");
    if (cursorBorder) {
      cursorBorder.style.left = `${x}px`;
      cursorBorder.style.top = `${y}px`;
    }
  };

  const handleMouseDown = () => setIsClicking(true);
  const handleMouseUp = () => setIsClicking(false);

  useEffect(() => {
    document.addEventListener("mousemove", move);
    document.addEventListener("touchmove", move);
    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", move);
      document.removeEventListener("touchmove", move);
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  return (
    <>
      <style>
        {`
          #cursor {
            position: fixed;
            background-color: ${isClicking ? "#fff" : "#fff"};
            height: 10px;
            width: 10px;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            pointer-events: none;
            transition: background-color 0.2s ease;
            z-index: 9999;
          }
          #cursor-border {
            position: fixed;
            width: 50px;
            height: 50px;
            background-color: transparent;
            border: 3px solid ${buttonHovered ? "red" : "#45a7ef"};
            border-radius: 50%;
            transform: translate(-50%, -50%);
            pointer-events: none;
            transition: all 0.2s ease-out;
            z-index: 9998;
          }
        `}
      </style>
      <div
        id="cursor"
        style={{ left: `${cursorX}px`, top: `${cursorY}px` }}
      ></div>
      <div id="cursor-border"></div>
    </>
  );
};

export default CustomCursor;
