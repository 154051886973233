/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [activeTwo, setActiveTwo] = useState(false);
  return (
    <>
      <header className="header">
        <div className="container">
          <a href="#" className="nav-btn">
            <span />
            <span />
            <span />
          </a>
          <div className="row align-items-center">
            {/*=============== LOGO ===============*/}
            <div className="col-12 col-sm-6 col-lg-2 logo-item">
              <Link to={'/'} className="logo">
                <img src="/img/logo.png" alt="logo" />
              </Link>
            </div>
            {/*============= LOGO END =============*/}
            <div className="col-8 nav-menu-cover">
              {/*============= NAV MENU =============*/}
              <nav className="nav-menu">
                <ul className="nav-list">
                  <li className="active">
                    <Link to={"/"}>Home</Link>
                  </li>
                  <li>
                    <Link to={"/about"}>About</Link>
                  </li>
                  <li
                    className={`dropdown ${activeTwo ? "active" : ""}`}
                    onMouseOver={() => setActiveTwo(true)}
                    onMouseOut={() => setActiveTwo(false)}
                  >
                    <Link
                      to={"/library"}
                      onMouseOver={() => setActiveTwo(true)}
                      onMouseOut={() => setActiveTwo(false)}
                    >
                      Programs{" "}
                      <i className="fa fa-angle-down" aria-hidden="true" />
                    </Link>
                    <ul
                      onMouseOver={() => setActiveTwo(true)}
                      onMouseOut={() => setActiveTwo(false)}
                    >
                      <li>
                        <Link to={"/library"}>Library Overview</Link>
                      </li>
                      <li>
                        <Link to={"/profile"}>User Profiles</Link>
                      </li>
                      <li>
                        <Link to={"/program"}>Programs</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to={"/restreat"}>Retreats</Link>
                  </li>
                  <li>
                    <Link to={"/wearable"}>AI Wearables</Link>
                  </li>
                  <li>
                    <Link to={"/community"}>Community</Link>
                  </li>
                  <li>
                    <Link to={"/social"}>Social Awareness</Link>
                  </li>
                  <li>
                    <Link to={"/contact"}>Contact Us</Link>
                  </li>
                </ul>
              </nav>
              {/*=========== NAV MENU END ===========*/}
            </div>
            <div className="col-12 col-sm-6 col-lg-2">
              {/*============ HEADER ICON ============*/}
              <ul className="header-icon">
                <li className="search">
                  <a
                    href="javascript:void(0)"
                    className="btn"
                  >
                    Donate Now
                  </a>
                </li>
              </ul>
              {/*========== HEADER ICON END ==========*/}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
