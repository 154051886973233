/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Layout from "../components/Layout/Layout";

const LibraryOverview = () => {
  return (
    <>
      <Layout>
        <div
          className="page-title"
          style={{ backgroundImage: 'url("/img/bg-page.jpg")' }}
        >
          <div className="container">
            <h1 className="title">LIBRARY OVERVIEW</h1>
          </div>
        </div>
        <section className="s-we-offer">
          <div className="container">
            <h2> LIBRARY OVERVIEW</h2>
            <p className="slogan">
              Immsy offers a comprehensive range of content and services to meet
              various wellness and personal development needs, tailored to users
              of all ages, including seniors, children, military personnel, and
              frontline workers. Explore our main categories and included
              services:
            </p>
            <div className="row we-offer-cover">
              <div className="col-md-6 we-offer-item">
                <div className="offer-item-img">
                  <img src="/img/img-serv-1.jpg" alt="img" />
                </div>
              </div>
              <div className="offer-item-content col-md-6">
                <div className="m-auto row">
                  <div className="col-lg-6 col-md-6">
                    <div className="reviewblock reviewblock--style2 mb-0">
                      <div className="serviceDetailsContent">
                        <h6 className="serviceDetailsContent__heading mb-20  mt-20">
                          Personal Development
                        </h6>
                        <ul className="ps-4">
                          <li style={{ listStyle: "disc" }}>
                            Personal Transformation
                          </li>
                          <li style={{ listStyle: "disc" }}>
                            Personal Productivity
                          </li>
                          <li style={{ listStyle: "disc" }}>Leadership</li>
                          <li style={{ listStyle: "disc" }}>
                            Career Development
                          </li>
                          <li style={{ listStyle: "disc" }}>
                            Parenting and Family Relationships
                          </li>
                          <li style={{ listStyle: "disc" }}>Happiness</li>
                          <li style={{ listStyle: "disc" }}>
                            Religion and Spirituality
                          </li>
                          <li style={{ listStyle: "disc" }}>
                            Personal Branding
                          </li>
                          <li style={{ listStyle: "disc" }}>Creativity</li>
                          <li style={{ listStyle: "disc" }}>Influence</li>
                          <li style={{ listStyle: "disc" }}>
                            Self-Esteem and Confidence
                          </li>
                          <li style={{ listStyle: "disc" }}>
                            Stress Management
                          </li>
                          <li style={{ listStyle: "disc" }}>
                            Memory and Learning Methods
                          </li>
                          <li style={{ listStyle: "disc" }}>Motivation</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="reviewblock reviewblock--style2 mb-0">
                      <div className="serviceDetailsContent">
                        <h6 className="serviceDetailsContent__heading mb-20  mt-20">
                          Health and Fitness
                        </h6>
                        <ul className="ps-4">
                          <li style={{ listStyle: "disc" }}>Fitness</li>
                          <li style={{ listStyle: "disc" }}>General Health</li>
                          <li style={{ listStyle: "disc" }}>Sports</li>
                          <li style={{ listStyle: "disc" }}>
                            Nutrition and Diet Plans
                          </li>
                          <li style={{ listStyle: "disc" }}>Yoga</li>
                          <li style={{ listStyle: "disc" }}>Mental Health</li>
                          <li style={{ listStyle: "disc" }}>
                            Martial Arts and Self-Defense
                          </li>
                          <li style={{ listStyle: "disc" }}>
                            Safety and First Aid
                          </li>
                          <li style={{ listStyle: "disc" }}>Dance</li>
                          <li style={{ listStyle: "disc" }}>Meditation</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row we-offer-cover">
              <div className="offer-item-content col-md-6 px-5">
                <div className="eventsBlock__content ps-5 pe-0">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="reviewblock reviewblock--style2 mb-0">
                        <div className="serviceDetailsContent">
                          <h6 className="serviceDetailsContent__heading mb-20  mt-20">
                            Business and Career
                          </h6>
                          <ul className="ps-4">
                            <li style={{ listStyle: "disc" }}>
                              Entrepreneurship
                            </li>
                            <li style={{ listStyle: "disc" }}>Communication</li>
                            <li style={{ listStyle: "disc" }}>Management</li>
                            <li style={{ listStyle: "disc" }}>Sales</li>
                            <li style={{ listStyle: "disc" }}>
                              Business Strategy
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="reviewblock reviewblock--style2 mb-0">
                        <div className="serviceDetailsContent">
                          <h6 className="serviceDetailsContent__heading mb-20  mt-20">
                            Community Support
                          </h6>
                          <ul className="ps-4">
                            <li style={{ listStyle: "disc" }}>
                              Community Engagement
                            </li>
                            <li style={{ listStyle: "disc" }}>
                              Events and Workshops
                            </li>
                            <li style={{ listStyle: "disc" }}>
                              Wellness Challenges
                            </li>
                            <li style={{ listStyle: "disc" }}>
                              Q&amp;A Sessions{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="reviewblock reviewblock--style2 mb-0">
                        <div className="serviceDetailsContent">
                          <h6 className="serviceDetailsContent__heading mb-20  mt-20">
                            Exclusive Content
                          </h6>
                          <ul className="ps-4">
                            <li style={{ listStyle: "disc" }}>
                              Wellness Videos
                            </li>
                            <li style={{ listStyle: "disc" }}>
                              Articles and Podcasts
                            </li>
                            <li style={{ listStyle: "disc" }}>
                              Monthly Webinars
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="reviewblock reviewblock--style2 mb-0">
                        <div className="serviceDetailsContent">
                          <p>
                            This diverse array of content and services ensures
                            that Immsy caters to a wide range of wellness and
                            personal development needs, providing users with the
                            tools and support they need to achieve their goals
                            and improve their overall well-being.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="offer-item-img col-md-6">
                <img src="/img/img-serv-2.jpg" alt="img" />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default LibraryOverview;
