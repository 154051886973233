import React from "react";
import Layout from "../components/Layout/Layout";

const UserProfiles = () => {
  return (
    <>
      <Layout>
        <div
          className="page-title"
          style={{ backgroundImage: 'url("img/bg-page.jpg")' }}
        >
          <div className="container">
            <h1 className="title">User Profile</h1>
          </div>
        </div>
        <section className="s-testimonials">
          <div className="container">
            <h2>User Profiles</h2>
            <p className="slogan">
              We offer personalized solutions for a variety of user profiles to
              meet their specific health and wellness needs.
            </p>
            <div
              className="slider-testimonials slick-initialized slick-slider slick-dotted container"
              role="toolbar"
            >
              <div aria-live="polite" className="slick-list draggable">
                <div className="slick-track row d-flex" role="listbox">
                  <div className="slide-testimonial slick-slide slick-cloned col-md-6">
                    <div className="testimonial-item">
                      <img src="/img/testimonials-2.png" alt="img" />
                      <h5 className="name">General User</h5>
                      <div className="testimon-content">
                        Individuals using the devices to monitor and improve
                        their daily health.
                      </div>
                      <h5 className="name">Frontline Users</h5>
                      <div className="testimon-content">
                        Medical Personnel: Tracking vital signs and health
                        parameters, communication tools for emergency care.
                      </div>
                      <div className="testimon-content">
                        Firefighters: Health and stress level monitoring,
                        recovery management after intense missions.
                      </div>
                      <div className="testimon-content">
                        Police Officers: Fitness and stress level tracking,
                        wellness programs specific to the profession.
                      </div>
                      <div className="testimon-content">
                        Adapted Exercises and Rehabilitation Programs: Specific
                        exercise and rehabilitation programs for seniors.
                      </div>
                    </div>
                  </div>
                  <div className="slide-testimonial slick-slide slick-cloned col-md-6">
                    <div className="testimonial-item">
                      <img src="/img/testimonials-3.jpg" alt="img" />
                      <h5 className="name">
                        Pre- and Post-Operative Individuals
                      </h5>
                      <div className="testimon-content">
                        Recovery tracking and health parameter monitoring,
                        medication reminders, and post-operative care
                        management.
                      </div>
                      <h5 className="name">High-Performance Athletes</h5>
                      <div className="testimon-content">
                        Sport Selection: Running: Cadence and technique
                        analysis. Cycling: Training and race tracking. Other
                        Sports: Personalized programs for various sports. Yoga,
                        Meditation, and Pranayama: Programs to improve
                        flexibility, mental strength, and stress management.
                        Corporate Offers: Wellness programs for athletic
                        employees.
                      </div>
                      <div className="testimon-content">
                        Swimming: Performance and technique tracking.
                      </div>
                      <div className="testimon-content">
                        Medication Reminders: Alerts for taking medications.
                      </div>
                    </div>
                  </div>
                  <div className="slide-testimonial slick-slide slick-cloned col-md-6">
                    <div className="testimonial-item">
                      <img src="/img/testimonials-2.png" alt="img" />
                      <h5 className="name">Children</h5>
                      <div className="testimon-content">
                        Growth and health tracking, interactive tools to
                        encourage an active and healthy lifestyle.
                      </div>
                      <h5 className="name">Family</h5>
                      <div className="testimon-content">
                        Family Member Tracking: Health tracking tools for all
                        family members.
                      </div>
                      <div className="testimon-content">
                        Family Health Groups: Platform for sharing health
                        progress and activities.
                      </div>
                      <div className="testimon-content">
                        Progress and Activity Sharing: Mutual encouragement and
                        support within the family.
                      </div>
                    </div>
                  </div>
                  <div className="slide-testimonial slick-slide slick-cloned col-md-6">
                    <div className="testimonial-item">
                      <img src="/img/testimonials-3.jpg" alt="img" />
                      <h5 className="name">Military Personnel</h5>
                      <div className="testimon-content">
                        Health and Fitness Monitoring: Tracking of physical
                        fitness and overall health.
                      </div>
                      <div className="testimon-content">
                        Stress Management Programs: Support for managing stress
                        and mental health.
                      </div>
                      <div className="testimon-content">
                        Recovery and Rehabilitation: Programs for recovery and
                        rehabilitation after injuries or missions.
                      </div>
                      <h5 className="name">Seniors</h5>
                      <div className="testimon-content">
                        Health and Physical Activity Monitoring: Surveillance of
                        health parameters and adapted physical activities.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default UserProfiles;
