// src/App.js
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import About from "./pages/About";
import LibraryOverview from "./pages/LibraryOverview";
import UserProfiles from "./pages/UserProfiles";
import Programs from "./pages/Programs";
import AIWereables from "./pages/AIWereables";
import Community from "./pages/Community";
import ContactUs from "./pages/ContactUs";
import Retreats from "./pages/Retreats";
import SocialAwarness from "./pages/SocialAwarness";
import CustomCursor from "./components/CustomCursor";

function App() {
  // const [loader, setLoader] = useState(false);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoader(false);
  //   }, 1000);
  // }, []);

  return (
    <>
      {/* {loader ? (
        <div className="preloader-cover">
          <div className="preloader">
            <div className="ajax-loader">
              <div className="ajax-loader-logo">
                <div className="ajax-loader-circle">
                  <svg className="ajax-loader-circle-spinner">
                    <circle cx="250" cy="250" r="239" />
                  </svg>
                </div>
                <div className="ajax-loader-letters">Q</div>
              </div>
            </div>
          </div>
        </div>
      ) : ( */}
      <>
        <CustomCursor />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<About />} />
            <Route path="/library" element={<LibraryOverview />} />
            <Route path="/profile" element={<UserProfiles />} />
            <Route path="/program" element={<Programs />} />
            <Route path="/wearable" element={<AIWereables />} />
            <Route path="/community" element={<Community />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/restreat" element={<Retreats />} />
            <Route path="/social" element={<SocialAwarness />} />
          </Routes>
        </BrowserRouter>
      </>
      {/* )} */}
    </>
  );
}

export default App;
