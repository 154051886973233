import React from "react";
import HomeHeroSection from "../components/Home/HomeHeroSection";
import Layout from "../components/Layout/Layout";
import LatestNews from "../components/Home/LatestNews";
import NewOpurtonies from "../components/Home/NewOpurtonies";
import OurProduct from "../components/Home/OurProduct";
import NewOffer from "../components/Home/NewOffer";
import Testimonial from "../components/Home/Testimonial";

const HomePage = () => {
  return (
    <>
      <Layout>
        <HomeHeroSection />
        <LatestNews />
        <NewOpurtonies />
        <OurProduct />
        <NewOffer />
        <Testimonial />
      </Layout>
    </>
  );
};

export default HomePage;
