import React from 'react'

const NewOpurtonies = () => {
  return (
    <>
<section className="s-info-bg mask" style={{ cursor: "none" }}>
  <div className="container" style={{ cursor: "none" }}>
    <div className="s-info-cover" style={{ cursor: "none" }}>
      <h2 style={{ cursor: "none" }}>new opportunities</h2>
      <p className="slogan" style={{ cursor: "none" }}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmmpor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud.
      </p>
      <div className="block-icon-cover row" style={{ cursor: "none" }}>
        <div className="col-6 col-md-3" style={{ cursor: "none" }}>
          <a
            href="shop-single.html"
            className="block-icon"
            style={{ cursor: "none" }}
          >
            <img src="img/icon-1.svg" alt="img" style={{ cursor: "none" }} />
            <h6 style={{ cursor: "none" }}>QuickShot</h6>
          </a>
        </div>
        <div className="col-6 col-md-3" style={{ cursor: "none" }}>
          <a
            href="shop-single.html"
            className="block-icon"
            style={{ cursor: "none" }}
          >
            <img src="img/icon-2.svg" alt="img" style={{ cursor: "none" }} />
            <h6 style={{ cursor: "none" }}>TapFly</h6>
          </a>
        </div>
        <div className="col-6 col-md-3" style={{ cursor: "none" }}>
          <a
            href="shop-single.html"
            className="block-icon"
            style={{ cursor: "none" }}
          >
            <img src="img/icon-3.png" alt="img" style={{ cursor: "none" }} />
            <h6 style={{ cursor: "none" }}>ActiveTrack</h6>
          </a>
        </div>
        <div className="col-6 col-md-3" style={{ cursor: "none" }}>
          <a
            href="shop-single.html"
            className="block-icon"
            style={{ cursor: "none" }}
          >
            <img src="img/icon-4.svg" alt="img" style={{ cursor: "none" }} />
            <h6 style={{ cursor: "none" }}>Gesture</h6>
          </a>
        </div>
      </div>
      <a href="shop.html" className="btn" style={{ cursor: "none" }}>
        view more
      </a>
    </div>
  </div>
</section>

    </>
  )
}

export default NewOpurtonies